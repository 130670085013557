<gm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [height]="height" (onMapReady)="onMapReady($event)"
  (onChangeZoom)="onChangeZoom($event)">
  <ng-container *ngIf="map">
    <gm-circle *ngIf="currentPoint?.latitude && currentPoint?.longitude" [map]="map" [latitude]="currentPoint?.latitude"
      [longitude]="currentPoint?.longitude" [radius]="radius" [fillColor]="'Blue'" [fillOpacity]="0.25"
      [strokeWeight]="0">
    </gm-circle>
    <gm-marker *ngIf="logistic === 'agro' || logistic === 'commodities'" [map]="map" [latitude]="currentPoint?.latitude"
      [longitude]="currentPoint?.longitude" [title]="currentPoint?.parada?.nome">
    </gm-marker>
    <gm-marker *ngFor="let pm of pointsMarker" [map]="map" [latitude]="pm.location.lat" [longitude]="pm.location.lng"
      [icon]="pm.location.icon" title="{{pm.location.descricao ? pm.location.label + ' - ' + pm.location.descricao : pm.location.label}}">
    </gm-marker>

    <!-- Primária -->
    <div *ngIf="logistic === 'primaria'" >
      <gm-marker *ngFor="let v of verticesFiltered" [map]="map" [latitude]="v.latitude" [longitude]="v.longitude"
      [icon]="v.icon" [title]="v.date">
      </gm-marker>
      <gm-polyline *ngIf="polylinePointsCarreta.length > 0" [map]="map" [path]="polylinePointsCarreta" 
        [strokeColor]="'#FF6F00'" [strokeColorArrow]="'#FF6F00'" [visible]="true" [showArrowDirection]="showArrowDirection"></gm-polyline>
      <gm-polyline *ngIf="polylinePoints.length > 0" [map]="map" [showArrowDirection]="showArrowDirection" [path]="polylinePoints" 
         [visible]="true"></gm-polyline>
    </div>    
    <!-- Fim Primária -->

    <!-- Distribuição -->
    <div *ngIf="logistic === 'distribuicao'">
      <gm-polyline *ngIf="polylinePoints.length > 0" [visible]="showTrailAchieved"
        [showArrowDirection]="showArrowDirection" [map]="map" [path]="polylinePoints"></gm-polyline>
      <gm-polyline *ngIf="polylinePointsProvided.length > 0" [visible]="showTrailProvided" [strokeColor]="'#808080'"
        [strokeColorArrow]="'#808080'" [showArrowDirection]="showArrowDirection" [map]="map"
        [path]="polylinePointsProvided"></gm-polyline>
    </div>
    <!-- Fim Distribuição -->

    <gm-polygon *ngFor="let pm of pointsMarker" [map]="map" [paths]="pm.location.pointsPolygon" [draggable]="false"
      [editable]="false" [clickable]="false" [fillColor]="'Red'" [fillOpacity]="0.25" [strokeWeight]="1">
    </gm-polygon>    
    <div *ngIf="directionsMarkerDisplay.length > 0">
      <gm-direction [visible]="showTrailDetail" [map]="map" [directionsList]="directionsMarkerDisplay"></gm-direction>
    </div>
  </ng-container>
</gm-map>