import { LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { CustomStorage } from '../../core/services/custom-storage';

export const authConfig = () => {
  return {
    config: {
      authority: `https://login.microsoftonline.com/${environment.tenantId}/v2.0`,
      authWellknownEndpointUrl: `https://login.microsoftonline.com/${environment.tenantId}/v2.0`,
      eagerLoadAuthWellKnownEndpoints: true,
      redirectUrl: `${window.location.origin}/return`,
      postLogoutRedirectUri: `${window.location.origin}/return`,
      clientId: environment.clientId,
      scope: `openid profile email api://${environment.clientId}/default offline_access`,
      responseType: 'code',
      // silentRenew: true,
      useRefreshToken: true,
      // tokenRefreshInSeconds: 60,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      // renewTimeBeforeTokenExpiresInSeconds: 300,
      // enableIdTokenExpiredValidationInRenew: false,
      // ignoreNonceAfterRefresh: true,
      // issValidationOff: true,
      autoUserInfo: false,
      logLevel: LogLevel.Debug,
      startCheckSession: true,
      storage: new CustomStorage(),
      secureRoutes: ['https://graph.microsoft.com/', 'https://graph.windows.net/'],
    }
  };
}
