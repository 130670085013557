export enum SubzoneTypeEnum {
    NotExpectedSubzoneType = 0,
    Apoio = 1,
    AreaDescanso = 2,
    Balanca = 3,
    BarreiraSanitaria = 4,
    CarregamentoRacao = 5,
    CarregamentoCaixas = 6,
    DescarregamentoAves = 7,
    DescarregamentoOvos = 8,
    DescarregamentoSuinos = 9,
    FabricaRacoes = 10,
    Frigorifico = 11,
    GalpaoEspera = 12,
    Hotel = 13,
    Incubatorio = 14,
    Integrado = 15,
    Oficina = 16,
    PatioEspera = 17,
    PontoHigienizacao = 18,
    PontoTransbordo = 19,
    PontoIndevido = 20,
    Portaria = 21,
    PostoCombustivel = 22,
    ResidenciaMotorista = 23,
    Restaurante = 24,
    Transportador = 25    
}