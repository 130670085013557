import { StateColorEnum } from 'src/app/core/models/enums/state-color.enum';
import { TypePointIconUtil } from 'src/app/utils/type-point-icon.util';

export class SynopticMapThreePoint {

    #type: string;

    #icon: string;

    #color: string;

    #url: string;

    #text: string;

    #hide: boolean;

    constructor({
        type,
        threeCurrent,
        title,
        subtitle,
        urlPoint,
        threeFinalizedForced,
        theresAnomaly = false,
        anomalies = [],
        threeFinalizedSuccess = false,
        hide = false,
    }) {
        let color = StateColorEnum.DEFAULT;
        if (threeFinalizedSuccess) {
            color = StateColorEnum.OK;
        } else if ((theresAnomaly || anomalies?.length > 0 || threeFinalizedForced) && threeCurrent) {
            color = StateColorEnum.DANGER;
        } else if (threeCurrent) {
            color = StateColorEnum.CURRENT;;
        }
        this.#type = type;
        this.#icon = type != null ? TypePointIconUtil(type) : TypePointIconUtil('cliente');
        this.#color = color;
        this.#text = `${title ? title : ''} ${title && subtitle ? '-' : ''} ${subtitle ? subtitle : ''}`;
        this.#url = urlPoint;
        this.#hide = hide;
    }

    get type(): string {
        return this.#type;
    }

    get icon(): string {
        return this.#icon;
    }

    get color(): string {
        return this.#color;
    }

    get url(): string {
        return this.#url;
    }

    get text(): string {
        return this.#text;
    }

    get hide(): boolean {
        return this.#hide;
    }
}
