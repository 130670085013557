import * as moment from "moment";
import { TOption } from "src/app/components/select/select.component";
import { Drawing } from "src/app/models/google-maps/drawing.model";
import { Coordinate } from "../../models/google-maps/coordinate.model";
import { IconsPrimary } from "./enums/icons-primary";
import { LogisticTypeEnum } from "./enums/logistic-type.enum";
import { SubzoneTypeEnum } from "./enums/subzone-type.enum";

export const PropertiesSubzone = {
    descricao: 'Descrição',
    rua: 'Rua',
    complemento: 'Complemento',
    numero: 'Número',
    bairro: 'Bairro',
    cidade: 'Cidade',
    estado: 'Estado',
    pais: 'País',
    cep: 'CEP',
    tipoUnidade: 'Tipo de Unidade',
    ativo: 'Ativo',
    responsavel: 'Responsável',
    telefone: 'Telefone',
    email: 'E-mail',
    pf_pj: 'Pessoa Física ou Jurídica',
    cpf_cnpj: 'CPF ou CPNJ',
    rg: 'RG/ID',
    observacao: 'Observações',
    restricaoSanitaria: 'Restrição Sanitária',
    raio: 'Raio',
    areaPoligono: 'Área do polígono'
};

export class TypesSubzoneOption {
    static getTypes(): TOption[] {
        return [
            { key: SubzoneTypeEnum.Apoio, display: 'Apoio', value: 'Apoio', checked: false },
            { key: SubzoneTypeEnum.AreaDescanso, display: 'Área de Descanso', value: 'Área de Descanso', checked: false },
            { key: SubzoneTypeEnum.Balanca, display: 'Balança', value: 'Balança', checked: false },
            { key: SubzoneTypeEnum.BarreiraSanitaria, display: 'Barreira Sanitária', value: 'Barreira Sanitária', checked: false },
            { key: SubzoneTypeEnum.CarregamentoRacao, display: 'Carregamento Ração', value: 'Carregamento Ração', checked: false },
            { key: SubzoneTypeEnum.CarregamentoCaixas, display: 'Carregamento Caixas', value: 'Carregamento Caixas', checked: false },
            { key: SubzoneTypeEnum.DescarregamentoAves, display: 'Descarregamento de Aves', value: 'Descarregamento de Aves', checked: false },
            { key: SubzoneTypeEnum.DescarregamentoOvos, display: 'Descarregamento de Ovos', value: 'Descarregamento de Ovos', checked: false },
            { key: SubzoneTypeEnum.DescarregamentoSuinos, display: 'Descarregamento de Suínos', value: 'Descarregamento de Suínos', checked: false },
            { key: SubzoneTypeEnum.FabricaRacoes, display: 'Fábrica Rações', value: 'Fábrica Rações', checked: false },
            { key: SubzoneTypeEnum.Frigorifico, display: 'Frigorífico', value: 'Frigorífico', checked: false },
            { key: SubzoneTypeEnum.GalpaoEspera, display: 'Galpão de Espera', value: 'Galpão de Espera', checked: false },
            { key: SubzoneTypeEnum.Hotel, display: 'Hotel', value: 'Hotel', checked: false },
            { key: SubzoneTypeEnum.Incubatorio, display: 'Incubatório', value: 'Incubatório', checked: false },
            { key: SubzoneTypeEnum.Integrado, display: 'Integrado', value: 'Integrado', checked: false },
            { key: SubzoneTypeEnum.Oficina, display: 'Oficina', value: 'Oficina', checked: false },
            { key: SubzoneTypeEnum.PatioEspera, display: 'Pátio de Espera', value: 'Pátio de Espera', checked: false },
            { key: SubzoneTypeEnum.PontoHigienizacao, display: 'Ponto de Higienização', value: 'Ponto de Higienização', checked: false },
            { key: SubzoneTypeEnum.PontoTransbordo, display: 'Ponto de Transbordo', value: 'Ponto de Transbordo', checked: false },
            { key: SubzoneTypeEnum.PontoIndevido, display: 'Ponto Indevido', value: 'Ponto Indevido', checked: false },
            { key: SubzoneTypeEnum.Portaria, display: 'Portaria', value: 'Portaria', checked: false },
            { key: SubzoneTypeEnum.PostoCombustivel, display: 'Posto de Combustível', value: 'Posto de Combustível', checked: false },
            { key: SubzoneTypeEnum.ResidenciaMotorista, display: 'Residência Motorista', value: 'Residência Motorista', checked: false },
            { key: SubzoneTypeEnum.Restaurante, display: 'Restaurante', value: 'Restaurante', checked: false },
            { key: SubzoneTypeEnum.Transportador, display: 'Transportador', value: 'Transportador', checked: false }
        ]
    }

    static getExitType(): TOption[] {
        return [
            { key: 0, display: 'Manual', value: 'Manual', checked: false },
            { key: 1, display: 'Posição', value: 'Posição', checked: false },
            { key: 2, display: 'Integração', value: 'Integração', checked: false },
            { key: 3, display: 'Posição ou integração', value: 'Posição ou integração', checked: false },
            { key: 4, display: 'Posição e integração', value: 'Posição e integração', checked: false },
        ]
    }
}


export class Subzone {
    public id: string;
    public codigo: string;
    public descricao: string;
    public endereco: Address;
    public tipoUnidade: number;
    public ativo: boolean;
    public maisInformacoes: DetailsSubzone;
    public referencia: Reference;
    public logistica: string;
    public checked: boolean;
    public historico: Historic[];

    constructor({
        id = '',
        codigo = '',
        descricao = '',
        endereco = {},
        tipoUnidade = 0,
        ativo = true,
        maisInformacoes = {},
        referencia = {},
        historico = [],
        logistica = ''
    }) {
        this.id = id;
        this.codigo = codigo;
        this.descricao = descricao;
        this.endereco = new Address(endereco);
        this.tipoUnidade = tipoUnidade;
        this.ativo = ativo;
        this.maisInformacoes = new DetailsSubzone(maisInformacoes);
        this.referencia = new Reference(this.id, referencia);
        this.referencia.setDrawing(
            new Drawing(
                id,
                descricao,
                new Coordinate(this.endereco.latitude, this.endereco.longitude),
                this.referencia.getAreaPontos(),
                this.referencia.getRaio(),
                this.referencia.getAreaPoligono()
            ));
        this.historico = historico.map((historic) => new Historic(historic));
        this.logistica = logistica;
        this.checked = false;
    }

    public addHistoric(historicList: Historic[]): Historic[] {
        return this.historico.concat(historicList);
    }

    static convertFormToJson(
        id: string,
        register: any,
        details: any,
        reference: any,
        logistic: string,
        usernameEdit: string = ''
    ) {
        return {
            id,
            descricao: register.descricao,
            codigo: register.codigo,
            endereco: {
                rua: details.rua,
                complemento: details.complemento,
                numero: details.numero,
                bairro: details.bairro,
                cidade: Array.isArray(register.cidade) ? register.cidade[0].value : register.cidade,
                estado: Array.isArray(register.estado) ? register.estado[0].value : register.estado,
                pais: Array.isArray(register.pais) ? register.pais[0].value : register.pais,
                longitude: parseFloat(reference.longitude),
                latitude: parseFloat(reference.latitude),
                uf: Array.isArray(register.estado) ? register.estado[0].key : register.estado,
                cep: details.cep
            },
            tipoUnidade: Array.isArray(register.tipoUnidade) ? register.tipoUnidade[0].key : register.tipoUnidade,
            ativo: register.ativo[0].key == 'sim',
            maisInformacoes: {
                responsavel: details.responsavel,
                telefone: details.telefone,
                email: details.email,
                pf_pj: details.pfPj == '' ? details.pfPj : details.pfPj[0].key,
                cpf_cnpj: details.cpfCnpj,
                rg: details.rg,
                observacao: details.observacao,
                dataCadastro: details.dataCadastro || moment().format('DD/MM/YYYY HH:mm'),
                dataUltimaAlteracao: id ? moment().format('DD/MM/YYYY HH:mm') : details.dataUltimaAlteracao,
                usuarioCadastro: details.usuarioCadastro,
                usuarioUltimaAlteracao: usernameEdit || details.usuarioUltimaAlteracao,
                restricaoSanitaria: details.restricaoSanitaria == '' ? '' : details.restricaoSanitaria[0].value
            },
            referencia: {
                areaPontos: !reference.areaPontos ? [] : reference.areaPontos.split('|'),
                raio: reference.raio,
                areaPoligono: reference.areaPoligono,
                dataCadastro: !reference.areaPontos ? '' : details.dataCadastro || moment().format('DD/MM/YYYY HH:mm'),
                poligono: reference.poligno,
            },
            historico: [],
            logistica: logistic
        }
    }

}


export class DetailsSubzone {
    public responsavel: string;
    public telefone: string;
    public email: string;
    public pf_pj: string;
    public cpf_cnpj: string;
    public rg: string;
    public observacao: string;
    public dataCadastro: string;
    public dataUltimaAlteracao: string;
    public usuarioCadastro: string;
    public usuarioUltimaAlteracao: string;
    public restricaoSanitaria: string;

    constructor({
        responsavel = '',
        telefone = '',
        email = '',
        pf_pj = '',
        cpf_cnpj = '',
        rg = '',
        observacao = '',
        dataCadastro = '',
        dataUltimaAlteracao = '',
        usuarioCadastro = '',
        usuarioUltimaAlteracao = '',
        restricaoSanitaria = ''
    }) {
        this.responsavel = responsavel;
        this.telefone = telefone;
        this.email = email;
        this.pf_pj = pf_pj;
        this.cpf_cnpj = cpf_cnpj;
        this.rg = rg;
        this.observacao = observacao;
        this.dataCadastro = dataCadastro;
        this.dataUltimaAlteracao = dataUltimaAlteracao;
        this.usuarioCadastro = usuarioCadastro;
        this.usuarioUltimaAlteracao = usuarioUltimaAlteracao;
        this.restricaoSanitaria = restricaoSanitaria;
    }
}

export class Reference {
    public idReference: string;
    public dataCadastro: string;
    public poligono: boolean;

    private areaPoligono: number;
    private raio: number;
    private areaPontos: Coordinate[];
    private drawing: any;


    constructor(
        idReference = null,
        {
            areaPontos = [],
            raio = 0,
            areaPoligono = 0,
            dataCadastro = null,
            poligono = true
        }
    ) {
        this.idReference = idReference;
        this.areaPontos = areaPontos.map((point: { latitude: number, longitude: number }) => new Coordinate(point.latitude, point.longitude));
        this.raio = parseFloat(raio.toFixed(6));
        this.areaPoligono = parseFloat(areaPoligono.toFixed(6));
        this.dataCadastro = dataCadastro;
        this.poligono = poligono;
    }

    setRaio(raio: number) {
        this.raio = parseFloat(raio.toFixed(6));
    }

    setAreaPoligono(areaPoligono: number) {
        this.areaPoligono = parseFloat(areaPoligono.toFixed(6));
    }

    setAreaPontos(areaPontos: Coordinate[]): void {
        this.areaPontos = areaPontos;
    }

    setDrawing(drawing: any): void {
        this.drawing = drawing;
    }

    getRaio(): number {
        return this.raio;
    }

    getAreaPoligono(): number {
        return this.areaPoligono;
    }

    getAreaPontos(): Coordinate[] {
        return this.areaPontos;
    }

    getDrawing(): Drawing {
        return this.drawing;
    }

    getPointsToString(): string {
        return this.areaPontos.map((coordinate: Coordinate) => coordinate.toString()).join('|');
    }
}


export class Address {

    public rua: string
    public complemento: string;
    public numero: string;
    public bairro: string;
    public cidade: string;
    public estado: string;
    public zip: string;
    public pais: string;
    public longitude: string;
    public latitude: string;
    public uf: string;
    public cep: string;

    constructor({
        rua = '',
        complemento = '',
        numero = '',
        bairro = '',
        cidade = '',
        estado = '',
        zip = '',
        pais = '',
        longitude = '',
        latitude = '',
        uf = '',
        cep = ''
    }) {
        this.rua = rua;
        this.complemento = complemento;
        this.numero = numero;
        this.bairro = bairro;
        this.cidade = cidade;
        this.estado = estado;
        this.zip = zip;
        this.pais = pais;
        this.longitude = longitude;
        this.latitude = latitude;
        this.uf = uf;
        this.cep = cep;
    }
}

export class Historic {
    public data: string;
    public usuario: string;
    public descricao: string;
    private propriedade: string;
    private de: string;
    private para: string;

    constructor({
        data = null,
        usuario = null,
        descricao = null,
        propriedade = null,
        de = null,
        para = null
    }) {
        this.data = data || moment().format('DD/MM/YYYY HH:mm');
        this.usuario = usuario;
        this.propriedade = propriedade;
        this.de = de;
        this.para = para;
        this.descricao = descricao || `<prop>${propriedade}</prop> alterado de <de>${de}</de> para <para>${para}</para>`;
        if (descricao) {
            this.propriedade = descricao.split('<prop>').pop().split('</prop>').shift();
            this.de = descricao.split('<de>').pop().split('</de>').shift();
            this.para = descricao.split('<para>').pop().split('</para>').shift();
        }
    }

    getPropriedade(): string {
        return this.propriedade;
    }

    getDe(): string {
        return this.de;
    }

    getPara(): string {
        return this.para;
    }
}


export class SubzonePrimary {
    public id: string;
    public logistica: string;
    public categoria: string;
    public nome: string;
    public codigo: string;
    public endereco: string;
    public cep: string;
    public tipoUnidade: string;
    public tipoSaida: number;
    public referencia: Reference;
    public dataCadastro: string;
    public latitude: string;
    public longitude: string;
    public icon: string;

    constructor({
        id = null,
        categoria = null,
        nome = null,
        codigo = null,
        endereco = null,
        cep = null,
        tipoUnidade = '',
        tipoSaida = null,
        referencia = {},
        latitude = null,
        longitude = null,
        logistica = LogisticTypeEnum.PRIMARY
    }) {
        this.id = id;
        this.categoria = categoria;
        this.nome = nome;
        this.codigo = codigo;
        this.endereco = endereco;
        this.cep = cep;
        this.tipoUnidade = tipoUnidade;
        this.tipoSaida = tipoSaida;
        this.referencia = new Reference(this.id, referencia);
        this.latitude = latitude;
        this.longitude = longitude;
        this.logistica = logistica;
        this.referencia.setDrawing(
            new Drawing(
                id,
                nome,
                new Coordinate(
                    this.latitude,
                    this.longitude
                ),
                this.referencia.getAreaPontos(),
                this.referencia.getRaio(),
                this.referencia.getAreaPoligono(),
                this.getIcon(this.tipoUnidade)
            ));
    }

    private getIcon(unitType: string) {
        switch (unitType) {
            case 'ARMAZEM':
                return IconsPrimary.ICON_WAREHOUSE;
            case 'CD':
            case 'UP':
                return IconsPrimary.ICON_CD;
            case 'CLIENTE':
                return IconsPrimary.ICON_CLIENT;
            case 'FORNECEDOR':
                return IconsPrimary.ICON_PROVIDER;
            case 'NF':
                return IconsPrimary.ICON_NF;
            case 'PORTO':
                return IconsPrimary.ICON_PORT;
            default:
                return IconsPrimary.ICON_CURRENT_POINT
        }
    }

    toJson(): any {
        return {
            id: `PRIMARIA_${this.codigo}`,
            logistica: this.logistica.toUpperCase(),
            codigo: this.codigo,
            nome: this.nome,
            endereco: this.endereco,
            cep: this.cep,
            categoria: this.categoria,
            tipoUnidade: this.tipoUnidade,
            tipoSaida: this.tipoSaida,
            latitude: this.latitude,
            longitude: this.longitude,
            referencia: {
                raio: this.referencia.getRaio(),
                areaPontos: this.referencia.getAreaPontos().map(point => point.toJsonPost()),
                areaPoligono: this.referencia.getAreaPoligono(),
                dataCadastro: this.dataCadastro,
                poligono: this.referencia.poligono
            }
        };
    }
}