import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem, MenuItemChild } from 'src/app/core/models/menu-item.model';
import { PerfilPermissao } from 'src/app/core/models/perfil-permissao.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { MenuItemOriginEnum } from 'src/app/enums/menu-item-origin.enum';
import { heightFadeAnimation } from '../../../core/animations/height-fade/height-fade.animation';
import { LogisticTypeEnum } from '../../../core/models/enums/logistic-type.enum';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [heightFadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, AfterViewInit {

    @Output() itemSelected = new EventEmitter<MenuItem | MenuItemChild>();

    @Input() list: MenuItem[] = [];

    public user: any;

    logistic = LogisticTypeEnum;

    constructor(
        private authService: AuthService,
        private router: Router,
        private menuService: MenuService,
        private perfilPermissaoService: PerfilPermissaoService,
        private cdf: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
            this.setActive();
        });
        const currentLogisticaRole = this.perfilPermissaoService.getCurrentLogisticaRole();
        this.getListMenu(currentLogisticaRole);
        this.setActive();
    }

    ngAfterViewInit(): void {
        this.authService.userData.subscribe((user) => {
            if (user != null) {
                this.user = user;
                this.cdf.detectChanges();
            }
        });
    }

    setActive(oldRoute?: string): void {
        const activeRoute = this.router.routerState.snapshot.url;
        this.list.map((parent: MenuItem) => {
            parent.children.map((children: MenuItemChild) => children.selected = false);
            const child = parent.children.find((children: MenuItemChild) => activeRoute === children.route);
            if (child) {
                return child.selected = parent.selected = parent.toggle = true;
            }
            parent.toggle = parent.selected = parent.route === activeRoute;
        });

        this.cdf.detectChanges();
    }

    getListMenu(perfilPermissao: PerfilPermissao): void {
        if (!perfilPermissao || !perfilPermissao.permissoes) {
            return null;
        }

        this.list = [];

        perfilPermissao.permissoes.forEach(modulo => {

            switch (modulo.codigo) {
                case 'monitoramento':
                    this.list.push(new MenuItem('MENU.MONITORING', MenuItemOriginEnum.Monitoring, ['agro', 'commodities'].includes(perfilPermissao.logistica) ? '/monitoring-v2' : '/monitoring/list', 'monitoring'));
                    break;

                case 'anomalia_consulta':
                    if (perfilPermissao.logistica !== LogisticTypeEnum.PRIMARY) {
                        this.list.push(new MenuItem('MENU.PANEL_ANOMALIES', MenuItemOriginEnum.Anomaly, '/anomaly-v2', 'anomaly'));
                    }
                    break;
                case 'disponibilidade':
                    this.list.push(new MenuItem('MENU.AVAILABILITY', MenuItemOriginEnum.Availability, '/availability', 'trucks'));
                    break;
                case 'retroalimentacao_consulta':
                    this.list.push(new MenuItem('MENU.FEEDBACK', MenuItemOriginEnum.Feedback, '/retroalimentacao', 'retro', false, false, this.getFeedbackChildren(perfilPermissao)));
                    break;
                // case "parametrizacao":
                //     this.list.push(new MenuItem("MENU.PARAMETERS_DISTRIBUTION", MenuItemOriginEnum.ParametersDistribution, '/parameters-distribution', 'register', false, false, this.getParamtersChildren(perfilPermissao)));
                //     break;
                case 'desvios':
                    this.list.push(new MenuItem('MENU.DELIVERY_RECEIPT', MenuItemOriginEnum.DeliveryReceipt, '/consultaCanhoto', 'notes-checklist-flip'));
                    break;
                case 'consulta_canhoto':
                    this.list.push(new MenuItem('MENU.DELIVERY_RECEIPT', MenuItemOriginEnum.DeliveryReceipt, '/consultaCanhoto', 'notes-checklist-flip'));
                    break;
                case 'painel_aeroporto':
                    this.list.push(new MenuItem('MENU.PANEL_AIRPORT', MenuItemOriginEnum.Airport, '/airport-panel', 'airport'));
                    break;
                case 'relatorios':
                    this.list.push(new MenuItem('MENU.REPORT', MenuItemOriginEnum.Report, '/reports', 'report', false, false, this.getReportChildren(perfilPermissao)));
                    break;
                case 'subareas':
                    this.list.push(new MenuItem('MENU.SUBZONES', MenuItemOriginEnum.Subzones, '/subzones', 'register'));
                    break;
                case 'transportadoras':
                    this.list.push(new MenuItem('MENU.CARRYING', MenuItemOriginEnum.Carrying, '/carriers', 'transport'));
                    break;
                case 'diarias':
                    this.list.push(new MenuItem('MENU.DAILY', MenuItemOriginEnum.Daily, '/diarias', 'date'));
                    break;
            }
        });
    }

    getReportChildren(perfilPermissao: PerfilPermissao): MenuItemChild[] {
        let children: MenuItemChild[] = [];
        switch (perfilPermissao.logistica) {
            case this.logistic.PRIMARY:
                children = [
                    new MenuItemChild('Anomalias', '/reports/anomalies'),
                    new MenuItemChild('Desvios', '/reports/deviations'),
                    new MenuItemChild('Processos', '/reports/process'),
                    new MenuItemChild('Rastro', '/reports/track'),
                    new MenuItemChild('Viagens', '/reports/travels'),
                    new MenuItemChild('Veículos', '/reports/vehicles')
                ];
                break;
            case this.logistic.DISTRIBUITION:
                children = [
                    new MenuItemChild('Baldeio', '/reports/bucket'),
                    new MenuItemChild('Devolução', '/reports/devolution'),
                    new MenuItemChild('Eficiência da equipe', '/reports/team-efficiency'),
                    new MenuItemChild('Gestão de canhotos', '/reports/ticket-management'),
                    new MenuItemChild('Viagem', '/reports/travel')
                ];
                break;
            case this.logistic.COMMODITIES:
                children = [
                    new MenuItemChild('Velocidade', '/reports/speed'),
                ];
                break;
            case this.logistic.AGRONOMY:
                break;
        }

        return children;
    }

    getFeedbackChildren(perfilPermissao: PerfilPermissao): MenuItemChild[] {
        let children: MenuItemChild[] = [];
        switch (perfilPermissao.logistica) {
            case this.logistic.DISTRIBUITION:
                children = [
                    new MenuItemChild('Tipos de Devolução', '/retroalimentacao/tipos-devolucao'),
                ];
                break;
        }

        return children;
    }

    getParamtersChildren(perfilPermissao: PerfilPermissao): MenuItemChild[] {
        let children: MenuItemChild[] = [];
        switch (perfilPermissao.logistica) {
            case this.logistic.DISTRIBUITION:
                children = [
                    new MenuItemChild('PNP', '/parameters-distribution/pnp')
                ];
                break;
        }
        return children;
    }

    goToUrl(item: MenuItem | MenuItemChild, e: MouseEvent): void {
        e.stopPropagation();
        if (item.route == '/subzones' || item.route == '/monitoring-v2') {
            window.location.href = window.location.origin + item.route;
        }
        this.router.navigateByUrl(item.route);
    }
}
