import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { DateAdapter } from '@angular/material/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { PerfilPermissao } from 'src/app/core/models/perfil-permissao.model';
import { MenuService } from 'src/app/core/services/menu.service';
import { ModalService } from 'src/app/core/services/modal';
import { ModalFilterPrimary } from 'src/app/components/modal/window/modal-filter-primary/modal-filter-primary.component';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {

  @Input() showMenu: boolean = false;

  currentLang: string;
  currentRole: string;
  currentLogistica: string;
  primariaRoles: PerfilPermissao[];
  distribuicaoRoles: PerfilPermissao[];
  agroRoles: PerfilPermissao[];
  commoditiesRoles: PerfilPermissao[];

  currentLogistic: any;

  showIconFilter: boolean = false;

  constructor(private authService: AuthService, private translate: TranslateService,
    private menuService: MenuService,
    private perfilPermissaoService: PerfilPermissaoService,
    protected router: Router,
    private dateAdapter: DateAdapter<any>,
    private modalService: ModalService,
  ) {
    this.translate.addLangs(['en', 'es', 'pt']);
    this.currentLang = this.translate.currentLang;

    if (!this.currentLang)
      this.currentLang = this.translate.defaultLang;
  }

  user: any = null;

  ngOnInit(): void {
    this.authService.userData.subscribe((user) => {
      this.user = user;
      if (this.user) {
        var isAdmin = false;
        for (var i = 0; i < this.user.roles.length; i++) {
          if ((this.user.roles[i] as string).toLowerCase() == "admin") {
            isAdmin = true;
            break;
          }
        }

        if (isAdmin) {
          this.user.roles = new Array<any>();
          this.user.roles.push("AdminPrimaria");
          this.user.roles.push("AdminDistribuicao");
          this.user.roles.push("AdminAgro");
          this.user.roles.push("AdminCommodities");
        }

        var currentLogisticaRole = this.perfilPermissaoService.getCurrentLogisticaRole();

        if (currentLogisticaRole && currentLogisticaRole.codigo) {
          this.currentRole = currentLogisticaRole.codigo;
        }

        if (!this.currentRole)
          this.currentRole = this.user.roles[0];

        var logisticaRoles = this.perfilPermissaoService.getLogisticaRoles(this.user.roles);

        this.primariaRoles = logisticaRoles.filter(p => p.logistica == "primaria");
        this.distribuicaoRoles = logisticaRoles.filter(p => p.logistica == "distribuicao");
        this.agroRoles = logisticaRoles.filter(p => p.logistica == "agro");
        this.commoditiesRoles = logisticaRoles.filter(p => p.logistica == "commodities");

        this.perfilPermissaoService.setCurrentLogisticaRole(this.currentRole);

        var logisticaRole = this.perfilPermissaoService.getLogisticaRole(this.currentRole);

        if (logisticaRole) {
          this.currentLogistica = logisticaRole.logistica;
          this.menuService.setMenus(logisticaRole);
        }
      }
    });

    this.currentLogistic = this.perfilPermissaoService.getCurrentLogisticaRole();
    if (LogisticTypeEnum.PRIMARY === this.currentLogistic?.logistica) {
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {          
          this.showIconFilter = event.url.includes('/anomaly') && event.url !== '/anomaly/list';
        }
      });
    }
  }

  openModalFilter() {
    this.modalService.create(ModalFilterPrimary, {})
      .onResult((result) => {
        if (result) this.router.navigate([`/monitoring/list`]);
      }).show();
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login')
      .then(() => {
        window.location.reload();
      });
  }

  changeLang(lang: string) {
    //this.translate.use(lang);
    //this.currentLang = this.translate.currentLang;
    //moment.locale(lang);
    //this.dateAdapter.setLocale(lang);
  }

  changeLogistica(role: string) {
    this.currentRole = role;
    this.perfilPermissaoService.setCurrentLogisticaRole(this.currentRole);
    var logisticaRole = this.perfilPermissaoService.getLogisticaRole(this.currentRole);
    this.currentLogistica = logisticaRole.logistica;

    if (['agro', 'commodities'].includes(logisticaRole.logistica)) {
      window.location.href = window.location.origin + '/monitoring-v2';
    } else {
      window.location.href = window.location.origin + '/monitoring/list';
    }
  }

  getIniciais(name) {
    var initiais = name.split(" ");
    var avatar = "";
    if (initiais.length == 1) {
      if (initiais[0])
        avatar += initiais[0][0]
    }

    if (initiais.length > 1) {
      if (initiais[0])
        avatar += initiais[0][0]

      if (initiais[1])
        avatar += initiais[1][0]
    }
    return avatar.toUpperCase();
  }

  toChat(): void {
    this.router.navigateByUrl('/chat');
  }
}
