export const environment = {
  production: true,

  // azure ad
  tenantId: '27951710-ebb9-4efb-a4a5-48e460822d4b',
  clientId: 'ad40953f-c002-4ec0-96e1-15b5ac87e1e8',

  base_api: 'https://brf-api-onelog.azurewebsites.net/api',
  base_signal_r_hub: 'https://brf-fnc-onelog-proc-hub.azurewebsites.net/api',

  // APIM Primary
  base_api_primary: 'https://apim.brf.cloud/portal-p/api',
  base_signal_r_hub_primary: 'https://apim.brf.cloud/hub-p/api',

  subscription_portal_primary: '046f789f6c1249efa9282b0db489de22',
  subscription_hub_primary: '4dcd904f12404122b4522a3097727db6',

  // APIM Distribution
  base_api_distribution: 'https://apim.brf.cloud/portal-d/api',
  base_api_distribution_diaria: "https://apim.brf.cloud/portal-gd/api",
  base_signal_r_hub_distribution: 'https://apim.brf.cloud/hub-d/api',

  subscription_portal_distribution: '534104e0a32e4e829cedf9a6d4530cb6',
  subscription_portal_distribution_diaria: "9378c7677673442a8b9712a253908440",
  subscription_hub_distribution: 'a233aa7610f84b1faf2e01e962667de3',

  // APIM Commodities / Agro
  base_api_agro_commodities: 'https://apim.brf.cloud/portal-ac/api',
  base_signal_r_hub_agro_commodities: 'https://apim.brf.cloud/hub-ac/api',

  subscription_portal_agro_commodities: 'edd1f37bfbc24fb18903b4bc16fad0be',
  subscription_hub_agro_commodities: '3af33c8104e04ce1ba3682c75a325a4c',

  googleMapsApi: {
    API_KEY: 'AIzaSyBm2Ln1E2KtNTS7yPmnb6Y9IqUxrRMj7Ns',
    VERSION: "weekly"
  },

  powerbi: {
    airport: "https://app.powerbi.com/reportEmbed?reportId=130214d0-4a80-4cbd-b747-7e28ed3048e2&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D",
    availability: "https://app.powerbi.com/reportEmbed?reportId=b8156aea-35bf-477b-86bf-3bed1867f9fa&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b",
    track: "https://app.powerbi.com/reportEmbed?reportId=56cea688-3e46-4ea2-84c8-038de81b3280&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
  }

};
