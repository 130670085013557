import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from './base.service';

import { RotaFilter } from '../models/filters/rota-filter';
import { MessageService } from './message.service';
import { FilterOptionsEnum } from '../enums/filter-options.enum';
import { ChatBusinessService } from 'src/app/components/chat/chat-business.service';
import { PerfilPermissaoService } from './perfil-permissao.service';
import { ChatPoolingService } from 'src/app/services/pooling/chat-pooling.service';
import { RoutePrimaryFilter } from '../models/filters/route-primary-filter.model';
import { SynopticMapPointDistribution } from 'src/app/models/synoptic-map/distribution/synoptic-map-point-distribution.model';
import { Anomaly } from 'src/app/models/anomaly.model';
import { LogisticTypeEnum } from '../models/enums/logistic-type.enum';


@Injectable()
export class MonitoramentoService extends BaseService<any> {
    private readonly MAX_LIMIT = FilterOptionsEnum.MAX_LIMIT;
    public points = [];
    constructor(
        _http: HttpClient,
        messageService: MessageService,
        private chatBusinessService: ChatBusinessService,
        perfilPermissaoService: PerfilPermissaoService,
        private chatPoolingService: ChatPoolingService
    ) {
        super(_http, 'monitoramento', messageService, perfilPermissaoService);
    }

    getAllPrimary(filter: RoutePrimaryFilter, isTransportadora: boolean = false): Observable<any> {

        filter.ultimaDataMensagemLida = this.chatBusinessService.getHistoryLastMessageReaded(filter.logistica);

        return this.http.post(this.url + (!isTransportadora ? '' : '/transportadora'), filter.toJson(), this.header())
            .pipe(
                map((resp: any) => {

                    if (resp.rotas?.length === this.MAX_LIMIT) {
                        resp['is_max_limit'] = true;
                    } else if (resp.rotas?.length > this.MAX_LIMIT) {
                        resp['is_max_limit'] = true;
                        resp.rotas = resp.rotas.slice(0, 99);
                    }

                    return resp as any;
                })
            )
    }

    getAll(filter: RotaFilter, isTransportadora: boolean = false): Observable<any> {
        if (filter == null)
            filter = new RotaFilter();

        const objFilter = this.getObjFilter(filter);

        return this.http.post(this.url + (!isTransportadora ? '' : '/transportadora'), objFilter, this.header())
            .pipe(
                map((resp: any) => {

                    if (resp.rotas?.length === this.MAX_LIMIT) {
                        resp['is_max_limit'] = true;
                    } else if (resp.rotas?.length > this.MAX_LIMIT) {
                        resp['is_max_limit'] = true;
                        resp.rotas = resp.rotas.slice(0, 99);
                    }

                    if (filter.logistica === 'distribuicao') {
                        try {
                            const viagens = resp.rotas?.map(rota => {
                                return { numeroViagem: rota?.numeroViagem, placa: rota?.veiculos[0]?.placa };
                            });

                            this.chatBusinessService.setSubscriptions(viagens);

                            this.chatPoolingService.subscribe();
                        } catch (e) {
                            console.error('[Monitoramento.Service] Não foi possível inserir numeroViagens/placas', e);
                        }
                    }

                    return resp as any;
                })
            )
    }

    lista: any[] = [];
    getTravels(logistica: string, numeroViagemSearch: string = null, pageIndex: number = 1, pageSize: number = 100): Observable<any> {

        if (this.lista.length == 0) {
            for (let i = 0; i < 1000; i++) {
                let id = (i + 1).toString();

                let random = Math.trunc(Math.random() * 10000).toString();
                let random2 = Math.trunc(Math.random() * 10000).toString();

                let final = id.concat(random).concat(random2).concat('0000000000').substring(0, 8);

                this.lista.push({ numeroViagem: final, linha: i + 1, logistica });
            }
        }

        var travels = new Observable<any>((observer) => {

            let timeoutId = setTimeout(() => {
                let listaFiltered = [];
                if (numeroViagemSearch) {
                    listaFiltered = this.lista.filter((item) => item.numeroViagem.toString().startsWith(numeroViagemSearch)).slice(0, pageSize);
                } else {
                    let inicio = (pageIndex - 1) * pageSize;
                    let fim = inicio + pageSize;

                    listaFiltered = this.lista.filter((item) => item.linha >= inicio && item.linha <= fim);
                }

                const result = {
                    lista: listaFiltered,
                    total: this.lista.length,
                    page: pageIndex
                }

                observer.next(result);

                console.log('Dados obtidos com sucesso ', numeroViagemSearch)
            }, 300)

            return {
                unsubscribe: () => {
                    clearTimeout(timeoutId);
                }
            }
        })
        return travels;
    }

    getMonitoringDistribuition(filter: RotaFilter, isTransportadora: boolean = false): Observable<any> {

        if (filter == null)
            filter = new RotaFilter();

        const objFilter = this.getObjFilter(filter);

        return this.http.post<any>(`${this.baseApiDistribution}/monitoramento-resumo`, objFilter)
    }

    getStepsDistribution(numeroViagem: string): Observable<{ paradas: SynopticMapPointDistribution[], anomalias: Anomaly[] }> {
        return this.http.get<any>(
            `${this.baseApiDistribution}/monitoramento-parada-anomalia-resumo?numeroViagem=${numeroViagem}&logistica=${LogisticTypeEnum.DISTRIBUITION}`
        ).pipe(
            map(result => {
                let anomalyNotStep = [];
                result.anomalias.forEach(anomaly => {
                    const index = result.paradas.findIndex(step => step.id === anomaly.idParada)
                    if (index < 0) {
                        anomalyNotStep.push(new Anomaly(anomaly));
                    }
                });
                return {
                    paradas: result.paradas.map(step => new SynopticMapPointDistribution(
                        {
                            ...step,
                            tipoParada: step.tipoParada || 'cliente',
                            anomalias: result.anomalias.filter(anomaly => {
                                if (anomaly.idParada === step.id) {
                                    return new Anomaly(anomaly);
                                }
                            })
                        }
                    )),
                    anomalias: anomalyNotStep
                }
            }
            ),
        );
    }

    private getObjFilter(filter) {
        let objFilter: any = {};

        if (filter.dataEntregaInicio) {
            objFilter.dataEntregaInicio = filter.dataEntregaInicio;
        }

        if (filter.dataEntregaFim) {
            objFilter.dataEntregaFim = filter.dataEntregaFim;
        }

        if (filter.dataColetaInicio) {
            objFilter.dataColetaInicio = filter.dataColetaInicio;
        }

        if (filter.dataColetaFim) {
            objFilter.dataColetaFim = filter.dataColetaFim;
        }

        if (filter.dataAgendaInicio) {
            objFilter.dataAgendaInicio = filter.dataAgendaInicio;
        }

        if (filter.dataAgendaFim) {
            objFilter.dataAgendaFim = filter.dataAgendaFim;
        }

        if (filter.dataInicioPrevistoInicio) {
            objFilter.dataInicioPrevistoInicio = filter.dataInicioPrevistoInicio;
        }

        if (filter.dataInicioPrevistoFim) {
            objFilter.dataInicioPrevistoFim = filter.dataInicioPrevistoFim;
        }

        if (filter.dataTerminoPrevistoInicio) {
            objFilter.dataTerminoPrevistoInicio = filter.dataTerminoPrevistoInicio;
        }

        if (filter.dataTerminoPrevistoFim) {
            objFilter.dataTerminoPrevistoFim = filter.dataTerminoPrevistoFim;
        }


        if (filter.dataInicioRealInicio) {
            objFilter.dataInicioRealInicio = filter.dataInicioRealInicio;
        }

        if (filter.dataInicioRealFim) {
            objFilter.dataInicioRealFim = filter.dataInicioRealFim;
        }

        if (filter.dataTerminoRealInicio) {
            objFilter.dataTerminoRealInicio = filter.dataTerminoRealInicio;
        }

        if (filter.dataTerminoRealFim) {
            objFilter.dataTerminoRealFim = filter.dataTerminoRealFim;
        }

        if (filter.dataViagemInicio) {
            objFilter.dataViagemInicio = filter.dataViagemInicio;
        }

        if (filter.dataViagemFim) {
            objFilter.dataViagemFim = filter.dataViagemFim;
        }

        if (filter.transportadora && filter.transportadora.length > 0) {
            objFilter.transportadora = filter.transportadora;
        }

        if (filter.regional && filter.regional.length > 0) {
            objFilter.regional = filter.regional;
        }

        if (filter.unidade && filter.unidade.length > 0) {
            objFilter.unidade = filter.unidade;
        }

        if (filter.empresa && filter.empresa.length === 1) {
            objFilter.empresa = filter.empresa[0];
        }

        if (filter.origem && filter.origem.length > 0) {
            objFilter.origem = filter.origem;
        }

        if (filter.destino && filter.destino.length > 0) {
            objFilter.destino = filter.destino;
        }

        if (filter.numeroViagem && filter.numeroViagem.length > 0) {
            objFilter.numeroViagem = filter.numeroViagem;
        }

        if (filter.placa && filter.placa.length > 0) {
            objFilter.placa = filter.placa;
        }

        if (filter.pedidos && filter.pedidos.length > 0) {
            objFilter.pedidos = filter.pedidos;
        }

        if (filter.codigoCliente && filter.codigoCliente.length > 0) {
            objFilter.codigoCliente = filter.codigoCliente;
        }

        if (filter.nomeCliente && filter.nomeCliente.length > 0) {
            objFilter.nomeCliente = filter.nomeCliente;
        }

        if (filter.remessa && filter.remessa.length > 0) {
            objFilter.remessa = filter.remessa;
        }

        if (filter.ocr && filter.ocr.length > 0) {
            objFilter.ocr = filter.ocr;
        }

        if (filter.statusEspelhamento && filter.statusEspelhamento.length > 0) {
            objFilter.statusEspelhamento = filter.statusEspelhamento;
        }

        if (filter.statusAtendimento && filter.statusAtendimento.length > 0) {
            objFilter.statusAtendimento = filter.statusAtendimento;
        }

        if (filter.statusViagem && filter.statusViagem.length > 0) {
            objFilter.statusViagem = filter.statusViagem;
        }

        if (filter.tipoFrota && filter.tipoFrota.length > 0) {
            objFilter.tipoFrota = filter.tipoFrota;
        }

        if (filter.tipoEquipamento && filter.tipoEquipamento.length > 0) {
            objFilter.tipoEquipamento = filter.tipoEquipamento;
        }

        if (filter.tipoOperacao && filter.tipoOperacao.length > 0) {
            objFilter.tipoOperacao = filter.tipoOperacao;
        }

        if (filter.tipoProjeto && filter.tipoProjeto.length > 0) {
            objFilter.tipoProjeto = filter.tipoProjeto;
        }

        if (filter.mercado && filter.mercado.length > 0) {
            objFilter.mercado = filter.mercado;
        }

        if (filter.tipoContratacao && filter.tipoContratacao.length > 0) {
            objFilter.tipoContratacao = filter.tipoContratacao;
        }

        if (filter.tipoVeiculo && filter.tipoVeiculo.length > 0) {
            objFilter.tipoVeiculo = filter.tipoVeiculo;
        }

        if (filter.tipoCliente && filter.tipoCliente.length > 0) {
            objFilter.tipoCliente = filter.tipoCliente;
        }

        if (filter.logistica && filter.logistica != "") {
            objFilter.logistica = filter.logistica;
        }

        if (filter.tipoProjeto && filter.tipoProjeto.length > 0) {
            objFilter.tipoProjeto = filter.tipoProjeto;
        }

        if (filter.tipoOperacao && filter.tipoOperacao.length > 0) {
            objFilter.tipoOperacao = filter.tipoOperacao;
        }

        if (filter.cliente) {
            objFilter.cliente = filter.cliente;
        }

        objFilter.ultimaDataMensagemLida = this.chatBusinessService.getHistoryLastMessageReaded(filter.logistica);

        return objFilter;
    }
}
