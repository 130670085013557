import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmMapComponent } from './gm-map.component';
import { AgmCoreModule } from '@agm/core';
import { env } from 'process';
import { environment } from 'src/environments/environment';


@NgModule({
    declarations: [GmMapComponent],
    exports: [GmMapComponent],
    imports: [
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapsApi.API_KEY,
            apiVersion: environment.googleMapsApi.VERSION,
            libraries: ['places', 'drawing', 'geometry']
        }),
    ]
})
export class GmMapModule { }
