import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DiariaDetail } from 'src/app/models/diarias/diaria-detail.interface';
import { ResponsibleArea } from 'src/app/models/diarias/responsible-area.interface';
import { DiariaReason } from 'src/app/models/diarias/diaria-reason.interface';
import { DiariaFilterClient } from 'src/app/models/diarias/diaria-filter-client.interface';
import { SelectBoxOptionsResponse } from 'src/app/models/diarias/selectBoxOptions.interface';
import { Diarias } from 'src/app/models/diarias.model';
import { DiariaApprovalRequest, DiariaManualApprovalRequest } from 'src/app/models/diarias/diaria-approval.interface';
import { PerfilPermissaoService } from './perfil-permissao.service';

@Injectable({
  providedIn: 'root'
})
export class DiariaService {
  private static cachedClientsList: DiariaFilterClient[];
  private static cachedIndustriesList: string[];
  private static cachedSelectBoxOptionsRecord: SelectBoxOptionsResponse<string> = {};
  private static cachedResponsibleAreasPromise: Promise<Array<ResponsibleArea>>;
  private static cachedReasonsPromise: Promise<Array<DiariaReason>>;

  private readonly logistic: string;

  private apiUrl = environment.base_api_distribution_diaria;

  private isLoadingDiarias: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private diariasListSubject: BehaviorSubject<Diarias[]> = new BehaviorSubject<Diarias[]>([]);

  private diariasCompleteListSubject: BehaviorSubject<Diarias[]> = new BehaviorSubject<Diarias[]>([]);

  constructor(protected http: HttpClient, private perfilService: PerfilPermissaoService) {
    this.logistic = this.perfilService.getCurrentLogisticaRole()?.logistica;
  }

  private buildUrl(path: string): string {
    return `${this.apiUrl}${path}`;
  }

  set listaDiarias(data: Diarias[]) {
    this.diariasListSubject.next(data);
  }

  get listaDiariasObservable(): Observable<Diarias[]> {
    return this.diariasListSubject;
  }

  set listaDiariasCompleta(data: Diarias[]) {
    this.diariasCompleteListSubject.next(data);
  }

  get listaDiariasCompletaObservable(): Observable<Diarias[]> {
    return this.diariasCompleteListSubject;
  }

  get loadingDiariasObservable(): Observable<boolean> {
    return this.isLoadingDiarias;
  }

  set loadingDiarias(value: boolean) {
    this.isLoadingDiarias.next(value);
  }

  getDiariaById(id: string): Observable<DiariaDetail> {
    return this.http.get(`${this.apiUrl}/diarias/${id}`).pipe(map((resp: any) => resp));
  }

  getAllDiarias(filters: Record<string, string | string[]> = {}): Promise<any> {
    const url = this.buildUrl('/diarias/listar-todas');
    const filtersEntries = Object.entries<string | string[]>(filters);
    const filtersWithValues = filtersEntries.filter(([, filterValue]) => Array.isArray(filterValue) ? filterValue.length > 0 : filterValue);

    const arrayFields = ['numeroViagem', 'placa', 'cliente', 'motivo', 'regiao', 'status'];
    const body = filtersWithValues?.reduce((acc, [filterKey, filterValue]) => {
      const formattedValue = arrayFields.includes(filterKey) && !Array.isArray(filterValue) ? [filterValue] : filterValue;
      return ({ ...acc, [filterKey]: formattedValue });
    }, {});

    return this.http.post(url, { ...body, logistica: this.logistic }).toPromise();
  }

  getDetailForManualRequest(logistic: string, travelNumber: string, plate: string): Promise<DiariaDetail> {
    const url = this.buildUrl(`/diarias/manual/${logistic}/${travelNumber}/${plate}`);
    return this.http.get<DiariaDetail>(url).toPromise();
  }

  postApprovalRequest(manualApproval: DiariaApprovalRequest): Observable<{ success: boolean }> {
    const url = this.buildUrl('/diarias/enviar-aprovacao');
    return this.http.post<{ success: boolean }>(url, manualApproval);
  }

  postManualApprovalRequest(manualApproval: DiariaManualApprovalRequest): Observable<{ success: boolean, [key: string]: any }> {
    const url = this.buildUrl('/diarias/sinalizar-manual');
    return this.http.post<{ success: boolean }>(url, manualApproval);
  }

  postBatchManualApprovalRequest(
    batchManualApproval: DiariaManualApprovalRequest[],
  ): Promise<{ success: boolean, messages: Record<string, string>[] }> {
    const url = this.buildUrl('/diarias/sinalizar-manual-lote');
    return this.http.post<{ success: boolean, messages: Record<string, string>[] }>(url, batchManualApproval).toPromise();
  }

  postTimeExceededApprovalRequest(params: { isApproved: boolean, id: string, idUsuarioLogado: string }): Promise<{ success: boolean }> {
    const { isApproved, ...body } = params;
    const url = this.buildUrl(`/diarias/torre/${isApproved ? 'aprovar' : 'reprovar'}`);
    return this.http.post<{ success: boolean }>(url, body).toPromise();
  }

  getAllResponsibleAreas(): Promise<Array<ResponsibleArea>> {
    if (!DiariaService.cachedResponsibleAreasPromise) {
      const url = this.buildUrl('/diarias/listar-areas');
      DiariaService.cachedResponsibleAreasPromise = this.http.get<Array<ResponsibleArea>>(url).toPromise();
    }
    return DiariaService.cachedResponsibleAreasPromise;
  }

  getAllReasons(): Promise<Array<DiariaReason>> {
    if (!DiariaService.cachedReasonsPromise) {
      const url = this.buildUrl('/diarias/listar-todos-motivos');
      DiariaService.cachedReasonsPromise = this.http.get<Array<DiariaReason>>(url).toPromise();
    }
    return DiariaService.cachedReasonsPromise;
  }

  getMyFilters(idUsuario: string): Promise<any> {
    return this.http.get(`${this.apiUrl}/filtro/lista/${idUsuario}/governanca-diaria-${this.logistic}`).toPromise();
  }

  createFilter(body: object): Promise<any> {
    return this.http.post(`${this.apiUrl}/filtro/inserir/`, body).toPromise();
  }

  updateFilter(body: object): Promise<any> {
    return this.http.put(`${this.apiUrl}/filtro/atualizar/`, body).toPromise();
  }

  deleteFilter(idFiltro: string, idUsuario: string): Promise<any> {
    return this.http.delete(`${this.apiUrl}/filtro/excluir/${idFiltro}/${idUsuario}`).toPromise();
  }

  async getClientsList(): Promise<DiariaFilterClient[]> {
    if (!DiariaService.cachedClientsList) {
      DiariaService.cachedClientsList = await this.http.get<DiariaFilterClient[]>(`${this.apiUrl}/diarias/listar-clientes`).toPromise();
    }
    return DiariaService.cachedClientsList;
  }

  async getIndustriesList(): Promise<string[]> {
    if (!DiariaService.cachedIndustriesList) {
      DiariaService.cachedIndustriesList = await this.http.get<string[]>(`${this.apiUrl}/diarias/listar-unidades`).toPromise();
    }
    return DiariaService.cachedIndustriesList;
  }

  async getSelectBoxOptions<K extends string>(fields: Array<K>): Promise<SelectBoxOptionsResponse<K>> {
    const existingFields = Object.keys(DiariaService.cachedSelectBoxOptionsRecord);
    const missingFields = fields.filter(field => !existingFields.includes(field));

    if (missingFields.length > 0) {
      const params = { logistica: this.logistic, campos: missingFields };
      const response = await this.http.get<SelectBoxOptionsResponse<K>>(`${this.apiUrl}/selectboxoptions`, { params }).toPromise();
      DiariaService.cachedSelectBoxOptionsRecord = { ...DiariaService.cachedSelectBoxOptionsRecord, ...response };
    }

    return DiariaService.cachedSelectBoxOptionsRecord;
  }
}
